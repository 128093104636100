<!--
 * @Author: your name
 * @Date: 2021-04-25 18:31:39
 * @LastEditTime: 2021-06-01 20:42:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\evalpay\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation :msg="msg.tipmsg"></navigation>
    <div class="nomsgcl"></div>
    <div class="themsgclass">
      <div class="imgdiv">
        <div class="momsg">
          <div>¥{{ msg.is_perfect == 1||msg.is_perfect == 2||msg.is_perfect == 3||msg.is_perfect == 4 ? "30" : msg.ispingjia?"50":'20' }}</div>
          <div>支付金额（元）</div>
        </div>
      </div>
      <div class="fs">支付方式</div>
      <div class="yu1">
        <div class="yu2">
          <img src="../../assets/qian.png" alt="" />
          <div>账户余额</div>
        </div>
        <img class="yu3" src="../../assets/gou.png" alt="" />
      </div>
      <button @click="paymeth" class="bu2">确认支付</button>
    </div>
    <!-- <div class="msgclass">
      <div class="zmc"><span>支付金额：</span><span>{{msg==0?'20':'30'}}元</span></div>
      <div class="fsc">支付方式：</div>
      <div class="paydiv">
        <div class="payclass">
          余额支付<img src="../../assets/duitop.png" alt="" />
        </div>
      </div>
    </div> -->
    <!-- <div class="buttonclass">
      <button @click="closepay" class="bu1">取消</button>
      <button @click="paymeth" class="bu2">确认支付</button>
    </div> -->
    <myalert @nometh="nometh" @okmeth="okmeth" :show="showalert">
        <div class="thealeclass">
          <img class="theimg" src="../../assets/tan.png" alt="" />
          <div class="themsgcc">
            <div>登录已过期，是否立即登录?</div>
          </div>
        </div>
      </myalert>
  </div>
</template>
<script>
import myalert from "../../components/myalert/index";
export default {
  components: {
    myalert,
  },
  data() {
    return {
      msg: "",
      showalert: false,
    };
  },
  created() {
    if(!localStorage.getItem("userId")){
      this.showalert = true;
    }
    this.msg = JSON.parse(this.$route.query.msg);
  },
  methods: {
    okmeth(){
       this.$router.push("/login");
    },
    nometh(){
      this.showalert = false;
      this.$router.go(-1);
    },
    closepay() {
      this.$router.go(-1);
    },
    paymeth() {
      if (this.msg.ispingjia) {
        //其他公司评价
        this.$http
          .post("/firm/v1/profile/evaluation_comments_payment_add", {
            reqType: "cost",
            pay_type: 4,
            staff_id: this.msg.id,
          })
          .then((res) => {
            this.$toast("购买成功");
            this.$router.go(-1);
          });
      } else {
        //购买简历
        this.$http
          .post("/firm/v1/resume/download_request_share", {
            reqType: "resume",
            pay_type: 10, //余额支付
            l_id: atob(this.msg.id),
            is_share:this.msg.isshare?'1':'0',
            share_user:this.msg.share_phone,
            subId:this.msg.subId //岗位id
          })
          .then((res) => {
            this.$toast(JSON.parse(res.data).msg);
            this.$toast("购买成功");
            this.$router.go(this.$route.query.backIndex?this.$route.query.backIndex:'-1');
          });
      }
    },
  },
};
</script>
<style scoped>
.themsgcc {
  font-size: 0.32rem;
  line-height: 0.48rem;
  color: #333333;
}
.theimg {
  width: 2rem;
  height: 2rem;
  margin-bottom: 0.3rem;
}
.thealeclass {
  text-align: center;
  margin: 0 0.25rem;
  margin-top: 0.62rem;
  margin-bottom: 0.8rem;
}
.nomsgcl {
  height: 0.33rem;
  background-color: #f5f7fa;
}
.yu1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.yu2 {
  display: flex;
  align-items: center;
  font-size: 0.32rem;
  line-height: 0.45rem;
  color: #141f33;
}
.yu2 img {
  width: 0.4rem;
  margin-right: 0.26rem;
}
.yu3 {
  width: 0.35rem;
}
.fs {
  margin-top: 0.29rem;
  margin-bottom: 0.47rem;
  font-size: 0.32rem;
  line-height: 0.45rem;
  color: #141f33;
}
.momsg {
  text-align: center;
}
.momsg > :first-child {
  font-size: 0.8rem;
  line-height: 1.12rem;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}
.momsg > :nth-child(2) {
  text-align: center;
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #ffffff;
  margin-top: 0.12rem;
}
.imgdiv {
  height: 4.22rem;
  background-image: url("../../assets/payback.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.themsgclass {
  background-color: #ffffff;
  padding: 0.3rem 0.46rem;
}
.buttonclass {
  position: fixed;
  bottom: 0;
  padding: 0.28rem 5% 0.28rem 5%;
  width: 90%;
  box-shadow: 0 -0.05rem 0.1rem rgb(243, 241, 241);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
}
.bu1 {
  font-size: 0.3rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.42rem;
  border-radius: 0.16rem;
  border: none;
  padding: 0.24rem 0.8rem;
  color: #ff5f19;
  background: #ffffff;
  border-radius: 0.16rem;
  border: 0.03rem solid #ff5f19;
}
.bu2 {
  font-size: 0.3rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  border-radius: 0.66rem;
  width: 100%;
  margin-top: 2rem;
  border: none;
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  padding: 0.27rem 1.5rem;
}
.paydiv {
  padding-left: 0.48rem;
}
.payclass img {
  width: 0.4rem;
  position: absolute;
  top: 0;
  right: 0;
}
.payclass {
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #333333;
  padding: 0.16rem 0.56rem;
  background-color: #ffede4;
  border: 0.03rem solid #ff4f05;
  border-radius: 0.06rem;
  display: inline-block;
  font-weight: bold;
  position: relative;
}
.fsc {
  padding: 0.48rem;
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #333333;
  font-weight: bold;
  padding-bottom: 0.53rem;
}
.zmc {
  padding-top: 0.86rem;
  padding-left: 0.48rem;
  padding-bottom: 0.32rem;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #dfdfdf;
}
.zmc > :first-child {
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #333333;
  margin-right: 0.15rem;
  font-weight: bold;
}
.zmc > :nth-child(2) {
  color: #ff2f2f;
  font-size: 0.6rem;
  line-height: 0.84rem;
  font-weight: bold;
}
.msgclass {
  margin: 0 0.38rem;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
</style>
